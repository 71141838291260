import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import dayjs from 'dayjs';
import React from 'react';
import {
  patchProductById,
  getProductById
} from '../../../services/Product';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
export default function UpdateProduct() {
  const [account, setAccount] = useState();
  const [releaseInfo, setReleaseInfo] = useState({});

  const [priority, setPriority] = React.useState('');
  const { productId = '' ,projectId=''} = useParams();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const validationSchema = yup.object({
    name: yup.string().required('Please enter name'),
    description: yup.string().required('Please enter description'),
    category: yup.string().required('Please enter category'),
  });

  /**
   * @description Create or edit release submission
   */
  const onReleaseFormSubmit = async () => {
    console.log('Update Release TOpic Called');
    setOpen(true);
    try {
      // Create new release
      const result = await patchProductById(projectId,productId, formik.values);
      // check release has been created or not
      if (result?.data) {
        enqueueSnackbar('Updated Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
        //navigate(`/products/${topicId}/release`);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      category:''
    },
    onSubmit: onReleaseFormSubmit,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };
  const getProductDetails = async () => {
    setOpen(true);
    try {
      const { data, status } = await getProductById(projectId,productId);
      console.log(data);
      if (!data) {
        throw new Error('Release not found');
      }
      setReleaseInfo(data);
      // Append form values
      formik.setValues({
        name: data.name,
        description: data.description,
        category: data.category
      });      
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while fething the release information', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.ERROR,
      });      
      setOpen(false);
    }
  };

  useEffect(() => {
    // get release informaton in case of edit
    if (productId) getProductDetails();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open}/>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} lg={8}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography variant="h5" color={'primary'}>
                Update Product
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}
          >
            {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    margin="normal"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="description"
                    name="description"
                    margin="normal"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="category"
                    name="category"
                    margin="normal"
                    label="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                    helperText={formik.touched.category && formik.errors.category}
                  />
                </Grid>
                {/* <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="linkedGroupId"
                    name="linkedGroupId"
                    margin="normal"
                    label="User Group"
                    value={formik.values.linkedGroupId}
                    onChange={formik.handleChange}
                    error={formik.touched.linkedGroupId && Boolean(formik.errors.linkedGroupId)}
                    helperText={formik.touched.linkedGroupId && formik.errors.linkedGroupId}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="version"
                    name="version"
                    margin="normal"
                    label="Release Version"
                    value={formik.values.version}
                    onChange={formik.handleChange}
                    error={formik.touched.version && Boolean(formik.errors.version)}
                    helperText={formik.touched.version && formik.errors.version}
                  />
                </Grid> */}
                <br />
                <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                  <Grid item>
                    <center>
                      <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                        Update
                      </Button>

                      <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="submit">
                        Clear
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
