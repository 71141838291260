import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import ReleaseTopicCharts from './ReleaseTopicCharts';
import ValidationPlansChart from './ValidationPlansChart';
import { Typography } from '@mui/material';
import MilestoneInsights from './Milestones';
import { CONTAINER_SIZE } from '../../styles/components/SizeConstants';

export default function Dashboard() {
  
  useEffect(() => {
   console.log(process.env.AUTH_HOST);
  }, []);
  return (
    <Container maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <Typography sx={{fontSize:30,fontWeight:'bold'}}>Dashboard {process.env.NODE_ENV}</Typography>
      <Typography sx={{fontSize:20}}>All your insights at one place</Typography>
      <ReleaseTopicCharts></ReleaseTopicCharts>
      <MilestoneInsights />
      <ValidationPlansChart />
    </Container>
  );
}
