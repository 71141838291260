import { styled } from '@mui/system';

export const ILabel = styled('span')({
  color: '#272727;',
  fontSize: 25,
});

export const IHeaderLabel = styled('p')({
  color: '#2c4389',
  fontSize: 30,
});



export const ITableHeader = ({
  color: '#2c4389',
  fontSize: 30,
});
