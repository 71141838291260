import { redirect } from 'react-router-dom';
import { APP_AUTH } from '../utils/constants/AppConstant';
/**
 * @description Guard function to check whether user has access to the route or not
 *
 * @param {String} param
 * @returns {Function}
 */
const protectedLoader = ({ request = {} }) => {
  
  const cookiesList = document.cookie.split(';');
  //console.log(cookiesList);
  let applicationCookie = null ;
  for(let i = 0; i < cookiesList.length; i++) {
    let cookie = cookiesList[i];
    let item = cookie.split('=');
    //console.log(item);
    const key = item[0].replace(" ",'')
    if(key===APP_AUTH.COOKIE_TOKEN_KEY){
      applicationCookie = item[1] ;
    }
  } 
  //console.log('Cookie Exisit ?',applicationCookie);
  if(!applicationCookie){
    return redirect("/login");
  }
  return null;
};

/**
 *
 * @description Guard function to redirect to default page if user already loggedin
 *
 * @param {*} param
 */
const unProtectedPageLoaded = ({ request = {} }) => {
  const cookiesList = document.cookie.split(';');
  console.log(cookiesList);
  let applicationCookie = null ;
  for(let i = 0; i < cookiesList.length; i++) {
    let cookie = cookiesList[i];
    let item = cookie.split('=');
    if(item[0]==='auth_token'){
      applicationCookie = item[1] ;
    }
  } 
 

  if (applicationCookie) {
    return redirect('/home/dashboard');
  }
  return null;
};

export const roleGuard = {
  protectedLoader,
  unProtectedPageLoaded,
};
