import React, { useEffect } from 'react';
import { getProjects, getProjectsById } from '../../services/Projects';

import {  useParams } from 'react-router-dom';
import { Projects } from '../../interface/Projects';
import ProjectSelector from '../shared-components/ProjectSelector/ProjectSelector';

export default function Selector() {
  const [selectedProject, setSelectedProject] = React.useState<Projects>();
  const [projects, setProjects] = React.useState<Array<Projects>>([]);
  const [dialogueOpen, setDialogueOpen] = React.useState<boolean>(false);
  const { projectId } = useParams();
  const handleClickOpen = () => {
    setDialogueOpen(true);
  };
  const fetchProjectsAPI = async () => {
    const projectResult = await getProjects();
    setProjects(projectResult.data.result);
  };
  const fetchProjectsByIdAPI = async (projectId: string) => {
    const projectResult = await getProjectsById(projectId);
    setSelectedProject(projectResult.data);
  };
  useEffect(() => {
    if (projectId) {
      fetchProjectsByIdAPI(projectId);
    } else {
      setDialogueOpen(true);
      fetchProjectsAPI();
    }
  }, []);

  return (
    <div>
      <ProjectSelector selectorComp={true}/>
    </div>
  );
}
