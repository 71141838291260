import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReleaseTopics } from '../../../utils/interface/ReleaseTopicInterface';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { updateStatus } from '../../../services/ReleaseTopics';
import { GridItemName, GridItemStatus, GridSecondaryItemName } from './styles/DetailsStyle';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';
import { BackdropSkeletonComponent } from '../../shared-components/backdrop/Backdrop';
interface Props {
  releaseTopic?: ReleaseTopics;
  open?: boolean;
}

export function HeaderDetails(props: Props): React.ReactElement {
  const { releaseTopic, open } = props;
  const { projectId = '', topicId = '' } = useParams();
  //const [releaseTopic, setReleaseTopic] = useState<ReleaseTopics>();
  const [status, setStatus] = React.useState(releaseTopic?.status || 'created');
  const [statusLoader, setStatusLoader] = React.useState(false);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const updateTopicStatus = async () => {
    if (topicId) {
      setStatusLoader(true);
      const result = await updateStatus(projectId, topicId, {
        status: status,
      });
      console.log('Completed ', result);
      setStatusLoader(false);
    }
  };
  useEffect(() => {
    if(releaseTopic && releaseTopic.status){
      setStatus(releaseTopic.status);
    }
  }, []);
  const content = () => {
    return (
      <>
        <Grid item xs={8}>
          <GridItemName>{releaseTopic?.name || 'Loading'}</GridItemName>
          <br />
          <GridSecondaryItemName>
            <strong>Planned Release Date </strong>
            {releaseTopic?.plannedReleaseDate ? ParseToDate(releaseTopic?.plannedReleaseDate) : 'Loading'}
          </GridSecondaryItemName>{' '}
          <DaysLeft date={releaseTopic?.plannedReleaseDate} status={releaseTopic?.status}></DaysLeft>
        </Grid>
        <Grid item xs={4}>
          <GridItemStatus>
            <Select
              disabled={statusLoader}
              labelId="priority-select-label"
              id="status"
              value={status}
              label="Status"
              onChange={handleStatusChange}
            >
              <MenuItem value={'created'}>Created</MenuItem>
              <MenuItem value={'freezed'}>Freezed</MenuItem>
              <MenuItem value={'grooming'}>Grooming</MenuItem>
              <MenuItem value={'breached'}>Breached</MenuItem>
              <MenuItem value={'recalled'}>Recalled</MenuItem>
              <MenuItem value={'completed'}>Completed</MenuItem>
            </Select>
            <LoadingButton
              loading={statusLoader}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={updateTopicStatus}
              sx={{ padding: 2 }}
            ></LoadingButton>
          </GridItemStatus>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <BackdropSkeletonComponent loading={open}>{content()}</BackdropSkeletonComponent>
    </Grid>
  );
}
