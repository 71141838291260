import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Skeleton } from '@mui/material';

export function BackdropComponent(props: any): React.ReactElement {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1,
      }}
      open={props.visible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export function BackdropSkeletonComponent(props: any): React.ReactElement {
  return (
    <>
      {props.loading ? (
        <Box sx={{width:'100%'}}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        props.children
      )}
    </>
  );
}
