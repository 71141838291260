export const DashboardStatusLayout = {
  margin: 2,
  padding: 2,
  textAlign: 'center',
};

export const DashboardPaperLayout = {
  margin: 2,
  width: 220,
  padding: 2,
  textAlign: 'center',
};

export const MilestonePaperLayout = {
  margin: 2,
  padding: 2,
  width: 420,
  textAlign: 'center',
};
export const SimpleFontGreenSmallStyle = { fontSize: 15, color: '#696e6b', fontWeight: 'bold' };
export const SimpleFontGreenBigStyle = { fontSize: 50, color: '#3a8761', fontWeight: 'bold' };
