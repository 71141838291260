import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createRelease, getReleaseById, updateRelease } from '../../../services/Release';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import dayjs from 'dayjs';
import React from 'react';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { UPDATE_SUCCESS } from '../../../translations/properties_dev';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';

export default function UpdateRelease() {
  const [releaseInfo, setReleaseInfo] = useState({});
  const [open, setOpen] = React.useState(false);
  const [priority, setPriority] = React.useState('');
  const [status, setStatus] = React.useState('');
  const { productId = '', releaseId = '', projectId = '' } = useParams();


  const validationSchema = yup.object({
    name: yup.string().required('Please enter name'),
    description: yup.string().required('Please enter description'),
    plannedReleaseDate: yup.string().required('Please enter planned release date'),
    version: yup.string().required('Please enter version'),
  });

  /**
   * @description Create or edit release submission
   */
  const onFormSubmit = async () => {
    try {
      setOpen(true);
      // Create new release
      const {data} = await updateRelease(projectId, productId,releaseId, formik.values);
      // check release has been created or not
      if (data) {
        enqueueSnackbar(UPDATE_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
        // navigate(`/products/${productId}/release`);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      plannedReleaseDate: '',
      linkedGroupId: '',
      version: '',
      reference: '',
      priority:'',
      status:''
    },
    onSubmit: onFormSubmit
  });

  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
    formik.setFieldValue('priority',event.target.value as string);
  };
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
    formik.setFieldValue('status',event.target.value as string);
  };
  const getRelaseByReleaseId = async () => {
    try {
      setOpen(true);
      const { data, status }  = await getReleaseById(projectId, productId, releaseId);

      if (!data) {
        setOpen(false);
        throw new Error('Release not found');
      }
      setReleaseInfo(data);
      // Append form values
      formik.setValues({
        name: data.name,
        description: data.description,
        plannedReleaseDate: dayjs(data.plannedReleaseDate) as any,
        linkedGroupId: data.linkedGroupId,
        version: data.version,
        reference: data.reference,
        priority: data.priority,
        status:data.status
      });
      setPriority(data.priority);
      setStatus(data.status);
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while fething the release information', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.ERROR,
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    if (releaseId) getRelaseByReleaseId();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Update Release Commitment" />
        <Grid item xs={8} md={8} lg={8}>
          {/* 
            List of attributes
            1. Release Topic name
            2. Description
            3. Planned Release date
            4. Priority (high,medium,low)
            5. Reference ()
            6. Release Demo (demo1)
            7. Source (person / document)
            8. Topic Owners (person-1,person-2,person-3)
          */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  margin="normal"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Status</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="status"
                    value={status}
                    label="Status"
                    sx={{ textAlign: 'left' }}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={'created'}>Created</MenuItem>
                    <MenuItem value={'planned'}>Planned</MenuItem>
                    <MenuItem value={'revoked'}>Revoked</MenuItem>
                    <MenuItem value={'committed'}>committed</MenuItem>
                    <MenuItem value={'breached'}>Breached</MenuItem>
                    <MenuItem value={'recalled'}>Recalled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  name="description"
                  margin="normal"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Planned Release Date"
                    name="plannedReleaseDate"
                    value={formik.values.plannedReleaseDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('plannedReleaseDate', Date.parse(value));
                    }}
                    slotProps={{
                      textField: {
                        id: 'plannedReleaseDate',
                        variant: 'outlined',
                        fullWidth: true,
                        sx: { marginTop: 2 },
                        error: formik.touched.plannedReleaseDate && Boolean(formik.errors.plannedReleaseDate),
                        helperText: formik.touched.plannedReleaseDate && formik.errors.plannedReleaseDate,
                      },
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Priority</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="priority"
                    value={priority}
                    label="Priority"
                    sx={{ textAlign: 'left' }}
                    onChange={handleChange}
                  >
                    <MenuItem value={'high'}>High</MenuItem>
                    <MenuItem value={'medium'}>Medium</MenuItem>
                    <MenuItem value={'low'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
             
              <Grid item xs={12} sx={{}}>
                <TextField
                  fullWidth
                  id="reference"
                  name="reference"
                  margin="normal"
                  label="Reference"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                />
              </Grid>

              {/* <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="linkedGroupId"
                  name="linkedGroupId"
                  margin="normal"
                  label="User Group"
                  value={formik.values.linkedGroupId}
                  onChange={formik.handleChange}
                  error={formik.touched.linkedGroupId && Boolean(formik.errors.linkedGroupId)}
                  helperText={formik.touched.linkedGroupId && formik.errors.linkedGroupId}
                />
              </Grid> */}
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="version"
                  name="version"
                  margin="normal"
                  label="Release Version"
                  value={formik.values.version}
                  onChange={formik.handleChange}
                  error={formik.touched.version && Boolean(formik.errors.version)}
                  helperText={formik.touched.version && formik.errors.version}
                />
              </Grid>
              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Save
                    </Button>

                    {/* <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="reset">
                      Clear
                    </Button> */}
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
