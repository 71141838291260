import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { ADD_USER_TO_ACOUNT, parseURI, UPDATE_USER_PASSWORD } from '../../../utils/parser/URIFormatter';
import { getUsers } from '../../../services/Auth';
import { TableHeaderLabel } from '../../../styles/components/Tables';
import { UsersListInterface } from '../../../utils/interface/UsersListInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { EmptyResult } from '../../shared-components/table/TableComponent';
import { useParams } from 'react-router-dom';
import PasswordIcon from '@mui/icons-material/Password';
export default function Users(props: Object): React.ReactElement {
  const { userId = '', projectId = '' } = useParams();
  const [tableData, setTableData] = useState<UsersListInterface>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const getUsersList = async (pageNUmber: number = 0) => {
    setOpen(true);
    const result = await getUsers();
    setTableData(result.data);
    setOpen(false);
  };
  useEffect(() => {
    getUsersList();
  }, [setTableData]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getUsersList(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Users" buttonHref={parseURI(ADD_USER_TO_ACOUNT)} buttonLabel="Create User" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={TableHeaderLabel}>First Name</TableCell>
                <TableCell sx={TableHeaderLabel}>Last Name</TableCell>
                <TableCell sx={TableHeaderLabel}>Email</TableCell>
                <TableCell sx={TableHeaderLabel}>Status</TableCell>
                <TableCell sx={TableHeaderLabel}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && tableData.result ? (
                tableData.result.map((row: any, index: number) => (
                  <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row?.firstName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.lastName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button variant="outlined" href={parseURI(UPDATE_USER_PASSWORD, projectId, row?._id)}>
                        <Tooltip title="Change Password">
                          <PasswordIcon />
                        </Tooltip>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyResult />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={PageCount(tableData?.totalCount)}
          sx={{ padding: 2, marginLeft: '30%' }}
          onChange={handleChange}
        />
      </Grid>
    </Container>
  );
}
