import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Container,
  Grid,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import React from 'react';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { DASHBOARD, parseURI, PROJECTS } from '../../../utils/parser/URIFormatter';
import { createProject } from '../../../services/Projects';
import { Navigate, useNavigate } from 'react-router-dom';

export default function CreateProject() {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const validationSchema = yup.object({});
  const submitReleaseTopic = async () => {
    console.log(`Rest API Call Save Release`);
    try {
      const result = await createProject(formik.values);
      console.log('result',result)
      if (result && result.data && result.data._id) {
        enqueueSnackbar('Release Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        navigate(parseURI(DASHBOARD,result.data._id));
      } else {
        enqueueSnackbar('Error in creating', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
      }
    } catch (err) {
      console.log(err);      
      enqueueSnackbar('Error in creating', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      ownerGroup: ''
    },
    onSubmit: submitReleaseTopic,
  });

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Create  Project" buttonHref={parseURI(PROJECTS)} buttonLabel="List Projects" />
        <Grid item xs={8} md={8} lg={8}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  margin="normal"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  name="description"
                  margin="normal"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="ownerGroup"
                  name="ownerGroup"
                  margin="normal"
                  label="Owners (one or more Users / emails)"
                  value={formik.values.ownerGroup}
                  onChange={formik.handleChange}
                  error={formik.touched.ownerGroup && Boolean(formik.errors.ownerGroup)}
                  helperText={formik.touched.ownerGroup && formik.errors.ownerGroup}
                />
              </Grid>

              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Save
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="submit">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
