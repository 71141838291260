import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';
/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getValidationPlansByReleaseTopic(projectId:string,releaseTopicId: String) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans-link/${releaseTopicId}`,
  });
  console.log(response);
  return response;
}

export async function getValidationPlans(projectId:string,pageNumber: number = 1) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
  });
  console.log(response);
  return response;
}

export async function getValidationPlanWithReleaseTopics(projectId:string,validationPlanId: string) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plan-links/${validationPlanId}/links`,
  });
  console.log(response);
  return response;
}

export async function createValidationPlan(projectId:string,releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans`,
    data: releaseObj,
  });

  return response;
}

export async function addValidationPlan(projectId:string,validationPlanId: string, payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans/${validationPlanId}/link`,
    data: payload,
  });
  return response;
}

export async function getValidationPlanById(projectId:string,validationPlanId: string) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans/${validationPlanId}`,
  });
  console.log(response);
  return response;
}


export async function patchValidationPlanById(projectId:string,validationPlanId: string, payload: Object = {}) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/validation-plans/${validationPlanId}`,
    data:payload
  });
  console.log(response);
  return response;
}