import { styled } from '@mui/material';
import { COLOR_THEME_PRIMARY, COLOR_THEME_WHITE } from '../../../styles/values/Colors';

export const NextMileStoneGrid = styled('div')({
  color: COLOR_THEME_WHITE,
  backgroundColor: COLOR_THEME_PRIMARY,
  fontSize: '30px',
  padding: '10px',
  textAlign: 'left',
  borderRadius:'10px',
  boxShadow:'0px 0px 4px #000'
});

export const ItemsMileStoneGrid = styled('div')({
  color: COLOR_THEME_PRIMARY,
  backgroundColor: COLOR_THEME_WHITE,
  fontSize: '20px',
  padding: '10px',
  textAlign: 'left',
});

export const IconMilestoneGrid = styled('div')({
  color: COLOR_THEME_WHITE,
  backgroundColor: COLOR_THEME_PRIMARY,
  display: 'inline',
  width: '20%',
  textAlign: 'left',
  paddingLeft: '5px',
  paddingRight: '5px',
});
export const ContentMilestoneGrid = styled('div')({
  color: COLOR_THEME_WHITE,
  backgroundColor: COLOR_THEME_PRIMARY,
  display: 'inline',
  width: '60%',
  fontSize: '30px',
  textAlign: 'left',
  paddingLeft: '5px',
  paddingRight: '5px',
});
export const ContentMilestoneSecondaryGrid = styled('div')({
  color: COLOR_THEME_WHITE,
  backgroundColor: COLOR_THEME_PRIMARY,
  fontSize: '20px',
  textAlign: 'left',
});
export const IconGrid = styled('div')({
  color: COLOR_THEME_PRIMARY,
  backgroundColor: COLOR_THEME_WHITE,
  display: 'inline',
  width: '20%',
  fontSize: '30px',
  textAlign: 'left',
  paddingLeft: '5px',
  paddingRight: '5px',
});
export const ContentGrid = styled('div')({
  color: COLOR_THEME_PRIMARY,
  backgroundColor: COLOR_THEME_WHITE,
  display: 'inline',
  width: '60%',
  fontSize: '30px',
  textAlign: 'left',
  paddingLeft: '5px',
  paddingRight: '5px',
});
