import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../utils/constants/AppConstant';
import { signUp } from '../../services/Auth';
import { BackdropComponent } from '../shared-components/backdrop/Backdrop';
export default function SignUp() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup.string().required('Please enter email'),
    password: yup.string().required('Please enter password'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      companyName: '',
      addressLine_1: '',
      addressLine_2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      rememberme: false,
    },
    onSubmit: async () => {
      const data = {
        companyName: formik.values.companyName,
        addressLine_1: formik.values.addressLine_1,
        addressLine_2: formik.values.addressLine_2,
        city: formik.values.city,
        state: formik.values.state,
        country: formik.values.country,
        zipCode: formik.values.zipCode,
        user: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          password: formik.values.password,
        },
      };
      setOpen(true);
      try {
        const result = await signUp(data);
        console.log('result ', result);
        if(result){
          enqueueSnackbar('Signed-up successfully , you will be notified once your account has been activated', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          });
          
          setOpen(false);
        }else{
          enqueueSnackbar('Encountering problems, Please try later', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          });
          setOpen(false);
        }
       
        navigate('/auth/login');
      } catch (err) {
        enqueueSnackbar('Error while login', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      }
    },
  });
  return (
    <Container component="main" maxWidth="xs">   
    <BackdropComponent visible={open} />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  autoComplete="CompanyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="addressLine_1"
                  label="Address Line 1"
                  name="addressLine_1"
                  value={formik.values.addressLine_1}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine_1 && Boolean(formik.errors.addressLine_1)}
                  helperText={formik.touched.addressLine_1 && formik.errors.addressLine_1}
                  autoComplete="addressLine_1"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="addressLine_2"
                  label="Address Line 2"
                  name="addressLine_2"
                  value={formik.values.addressLine_2}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine_2 && Boolean(formik.errors.addressLine_2)}
                  helperText={formik.touched.addressLine_2 && formik.errors.addressLine_2}
                  autoComplete="addressLine_2"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  autoComplete="city"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  fullWidth
                  id="state"
                  label="State"
                  name="state"
                  autoComplete="state"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  autoComplete="country"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                  fullWidth
                  id="zipCode"
                  label="Zip Code"
                  name="zipCode"
                  autoComplete="zipCode"
                />
              </Grid>
              {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  <RouteLink to="/auth/login">Already have an account? Log in</RouteLink>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
