import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../utils/constants/AppConstant';
import React from 'react';
import * as yup from 'yup';
import { changePassword } from '../../services/Auth';
import { BackdropComponent } from '../shared-components/backdrop/Backdrop';
import MainContentHeader from '../shared-components/main-content/MainContentHeader';
import { CONTAINER_SIZE } from '../../styles/components/SizeConstants';
import { useFormik } from 'formik';
import {  UPDATE_SUCCESS } from '../../translations/properties_dev';
export default function ChangePassword() {
  const [open, setOpen] = React.useState(false);

  const onFormSubmit = async () => {
    console.log('onReleaseFormSubmit Started');
    try {
      setOpen(true);
      // Create new release
      const payload = {
        currentPassword: formik.values.currentPassword,
        newPassword: formik.values.newPassword,
      };
      const result = await changePassword(payload);
      // check release has been created or not
      if (result.data) {
        enqueueSnackbar(UPDATE_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  const validationSchema = yup.object({
    currentPassword: yup.string().required('Please enter Current Password'),
    newPassword: yup.string().required('Please enter New Password'),
    confirmPassword: yup.string().required('Please enter Confirm Password'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: onFormSubmit,
  });

  const component = () => {
    return (
      <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
        <BackdropComponent visible={open} />
        <Grid container spacing={2}>
          <MainContentHeader label="Change Password" />
          <Grid item xs={8} md={8} lg={8}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={12} sx={{}}>
                  <span className="">Current Password</span>
                  <TextField
                    required
                    fullWidth
                    sx={{ fontSize: '30' }}
                    id="currentPassword"
                    type="password"
                    margin="normal"
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                    helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">New Password</span>
                  <TextField
                    required
                    fullWidth
                    sx={{ fontSize: '30' }}
                    id="newPassword"
                    type="password"
                    margin="normal"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">Confirm Password</span>
                  <TextField
                    required
                    fullWidth
                    sx={{ fontSize: '30' }}
                    id="confirmPassword"
                    type="password"
                    margin="normal"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  />
                </Grid>
                <br />
                <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                  <Grid item>
                    <center>
                      <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                        SAVE
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      {component()}
    </Container>
  );
}
