import { Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DashboardPaperLayout, SimpleFontGreenBigStyle, SimpleFontGreenSmallStyle } from './styles/Style';
import { useParams } from 'react-router-dom';
import { getValidationPlans } from '../../services/ValidationPlans';
import { BackdropSkeletonComponent } from '../shared-components/backdrop/Backdrop';
interface ValidationPlansResult {
  totalCount: number;
}
export default function ValidationPlansChart() {
  const { projectId = '' } = useParams();
  const [validationPlans, setValidationPlans] = React.useState<ValidationPlansResult>();
  const [loading,setLoading] = React.useState<boolean>(false);
  const validationListAPI = async () => {
    setLoading(true);
    const result = await getValidationPlans(projectId);
    setValidationPlans(result.data);
    setLoading(false);
  };
  useEffect(() => {
    validationListAPI();
  }, []);
  return (
    <BackdropSkeletonComponent loading={loading}>
      <Paper elevation={3} sx={DashboardPaperLayout}>
        <Typography sx={SimpleFontGreenBigStyle}>{validationPlans?.totalCount}</Typography>
        <Typography sx={SimpleFontGreenSmallStyle}>Number of validation plans</Typography>
      </Paper>
    </BackdropSkeletonComponent>
  );
}
