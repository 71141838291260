import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReleaseInsights, Releases } from '../../../utils/interface/ReleaseInterface';
import { BarChart } from '@mui/x-charts/BarChart';
import HeaderCard from '../../shared-components/header-card/HeaderCard';
import { PieChart } from '@mui/x-charts/PieChart';
import {
  getReleaseInsights,
  getReleaseById
} from '../../../services/Release';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
export function ReleaseManifest(props: any): React.ReactElement {
  const {projectId='',productId='',releaseId=''} = useParams();
  const [open, setOpen] = React.useState(false);
  const [release, setReleases] = useState<Releases>();
  const [releaseInsight, setReleaseInsight] = useState<ReleaseInsights>({});
  const releaseInsights = async() => {
    if(releaseId){
      setOpen(true);
      const releaseInsights = await getReleaseInsights(projectId,releaseId);
      setReleaseInsight(releaseInsights.data);
      setOpen(false);
    }
  }
  const releaseById = async() => {
    if(releaseId && productId){
      setOpen(true);
      const release = await getReleaseById(projectId,productId,releaseId);
      setReleases(release.data);
      setOpen(false);
    }
  }
  useEffect(() => {
      releaseById();
      releaseInsights();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
       <BackdropComponent visible={open} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <HeaderCard title={release?.name} description={release?.description} />
          </Grid>
          {/* {release?.status !== 'freezed' ? publishComponent() : releaseCallBack()} */}
        </Grid>
      </Box>
      <Paper elevation={3}>
        <PieChart
        sx={{marginTop:4,marginBottom:4}}
          series={[
            {
              data: [
                { id: 0, value: releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0, label: 'Accepted' },
                { id: 1, value: releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0, label: 'Denied' },
                {
                  id: 2,
                  value: releaseInsight?.noAcknowledgement ? releaseInsight?.noAcknowledgement : 0,
                  label: 'Not Acknowledged',
                },
                // releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0,
                // releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0,
                // 0,
              ],
            },
          ]}
          width={600}
          height={300}
        />
      </Paper>

      {/* <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: ['Accepted', 'Denied', 'Rejected'],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [
              releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0,
              releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0,
              0,
            ],
          },
        ]}
        width={500}
        height={300}
      /> */}
    </Container>
  );
}
