import { Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import React, { useEffect } from 'react';
import { getReleaseTopicStatusInsights } from '../../services/ReleaseTopics';
import { useParams } from 'react-router-dom';
import { DashboardStatusLayout } from './styles/Style';
import { BackdropSkeletonComponent } from '../shared-components/backdrop/Backdrop';
interface ReleaseTopicInsights {
  completedCount: number | undefined;
  createdCount: number | undefined;
  totalTopicsRecalledCount: number | undefined;
  totalTopicsBreachedCount: number | undefined;
  totalTopicsFreezedCount: number | undefined;
  totalTopicsCount:number | undefined;
  totalTopicsGroomingCount:number | undefined
}
export default function ReleaseTopicCharts() {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [loading,setLoading] = React.useState<boolean>(false);
  const [releaseTopicInsights, setReleaseTopicInsights] = React.useState<ReleaseTopicInsights>();
  const getReleaseTopicInsights = async () => {
    const result = await getReleaseTopicStatusInsights(projectId);
    setReleaseTopicInsights(result.data);
  };
  useEffect(() => {
    setLoading(true);
    getReleaseTopicInsights();
    setLoading(false);
  }, []);
  return (
    <BackdropSkeletonComponent loading={loading}>
      <Paper elevation={3} sx={DashboardStatusLayout}>
        <BarChart
          xAxis={[
            {
              id: 'release-topics',
              data: ['Created','Grooming', 'Completed', 're-called', 'Breached', 'Freezed','Total No.of Topics'],
              scaleType: 'band',
            },
          ]}
          // yAxis={
          //   [
          //     {
          //       id: 'totalCout',
          //       // data: [
          //       //   0,1,(releaseTopicInsights?.totalTopicsCount || 0)
          //       // ],
          //       scaleType: 'band',
          //     }
          //   ]
          // }
          series={[
            {
              data: [
                releaseTopicInsights?.createdCount || 0,
                releaseTopicInsights?.totalTopicsGroomingCount || 0,
                releaseTopicInsights?.completedCount || 0,
                releaseTopicInsights?.totalTopicsRecalledCount || 0,
                releaseTopicInsights?.totalTopicsBreachedCount || 0,
                releaseTopicInsights?.totalTopicsFreezedCount || 0,
                releaseTopicInsights?.totalTopicsCount || 0,
              ],
            },
          ]}
          height={300}
        />
      </Paper>
    </BackdropSkeletonComponent>
  );
}
