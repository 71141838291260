import dayjs from 'dayjs';

export function ParseInstant(datestr: string) {
  let date = Date.parse(datestr);

  return `${date.toLocaleString()}`;
}

export function ParseToDate(datestr: string | undefined | Date) {
  return dayjs(datestr).format('YYYY/MM/DD');
}

export function CalculateFromToday(datestr: Date) {
  const dateFrom = new Date(datestr);
  const today = new Date();
  let Difference_In_Time = dateFrom.getTime() - today.getTime();
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  console.log(`number of Days ${Difference_In_Days}`);
  return Difference_In_Days;
}
