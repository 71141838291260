import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import React from 'react';
import { createReleaseTopic } from '../../../services/ReleaseTopics';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { CREATED_SUCCESS } from '../../../translations/properties_dev';

export default function CreateReleaseTopics() {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [priority, setPriority] = React.useState('');

  const handlePriorityChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('priority', event.target.value as string);
    setPriority(event.target.value as string);
  };
  const validationSchema = yup.object({});
  const submitReleaseTopic = async () => {
    console.log(`Rest API Call Save Release`);
    setOpen(true);
    try {
      const result = await createReleaseTopic(projectId, formik.values);
      if (result.data) {
        enqueueSnackbar(CREATED_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      console.log(err);
      setOpen(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      plannedReleaseDate: '',
      priority: '',
      reference: '',
      topicSource: '',
      topicOwners: '',
    },
    onSubmit: submitReleaseTopic,
  });

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Create Release Topic" />
        <Grid item xs={8} md={8} lg={8}>
          {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  margin="normal"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  name="description"
                  margin="normal"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Planned Release Date"
                    name="plannedReleaseDate"
                    value={formik.values.plannedReleaseDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('plannedReleaseDate', Date.parse(value));
                    }}
                    slotProps={{
                      textField: {
                        id: 'plannedReleaseDate',
                        variant: 'outlined',
                        fullWidth: true,
                        sx: { marginTop: 2 },
                        error: formik.touched.plannedReleaseDate && Boolean(formik.errors.plannedReleaseDate),
                        helperText: formik.touched.plannedReleaseDate && formik.errors.plannedReleaseDate,
                      },
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Priority</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="priority"
                    value={priority}
                    label="Priority"
                    sx={{ textAlign: 'left' }}
                    onChange={handlePriorityChange}
                  >
                    <MenuItem value={'high'}>High</MenuItem>
                    <MenuItem value={'medium'}>Medium</MenuItem>
                    <MenuItem value={'low'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="reference"
                  name="reference"
                  margin="normal"
                  label="Reference"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="topicSource"
                  name="topicSource"
                  margin="normal"
                  label="Topic Source"
                  value={formik.values.topicSource}
                  onChange={formik.handleChange}
                  error={formik.touched.topicSource && Boolean(formik.errors.topicSource)}
                  helperText={formik.touched.topicSource && formik.errors.topicSource}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="topicOwners"
                  name="topicOwners"
                  margin="normal"
                  label="Topic Owners"
                  value={formik.values.topicOwners}
                  onChange={formik.handleChange}
                  error={formik.touched.topicOwners && Boolean(formik.errors.topicOwners)}
                  helperText={formik.touched.topicOwners && formik.errors.topicOwners}
                />
              </Grid>

              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Save
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="submit">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
