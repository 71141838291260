import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getRoadMaps } from '../../../services/RoadMap';
import {
  Container,
  Grid,
  Paper,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Divider,
} from '@mui/material';
import { IHeaderLabel } from '../../../styles/components';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { EmptyResult, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { RoadMapListInterface } from '../../../utils/interface/RoadMapListInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
export function ListRoadMap(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [roadMaps, setRoadMap] = useState<RoadMapListInterface>();
  const listRoadMaps = async (pageNumber: number = 1) => {
    const result = await getRoadMaps(projectId,pageNumber);
    setRoadMap(result);
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    listRoadMaps();
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    listRoadMaps(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <IHeaderLabel>Road Map</IHeaderLabel>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box textAlign={'right'} mr={4} mb={2}>
              <Button variant="contained" href={`/roadmaps/timelineview`}>
                Switch to timeline
              </Button>
              <Button variant="contained" href={`/roadmaps/create`}>
                <AddIcon />
                Create Road Map
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell
                    sx={{
                      color: '#2c4389',
                      fontSize: 20,
                    }}
                  >
                    Topic Name
                  </TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {roadMaps && roadMaps.result ? (
                  roadMaps.result.map((row: any, index: number) => (
                    <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(roadMaps?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
