import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Autocomplete,
  TextField,
  createFilterOptions,
  Card,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import CloseIcon from '@mui/icons-material/Close';
import { addValidationPlan, getValidationPlans, getValidationPlansByReleaseTopic } from '../../../services/ValidationPlans';
import { ValidationPlanInterface, ValidationPlanLinkInterface } from '../../../utils/interface/ValidationPlans';
import { ParseToDate } from '../../../utils/parser/DateParser';
type Props = {
  releaseTopicId: string | undefined;
};
const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: ValidationPlanInterface) => option?.name,
});
export function AddValidationPlan(props: Props): React.ReactElement {
  const [validationPlans, setValidationPlans] = useState<Array<ValidationPlanInterface>>([]);
  const [inputValue, setInputValue] = React.useState('');

  const { projectId = '' } = useParams();
  const [validationPlansList, setValidationPlansList] = useState<Array<ValidationPlanLinkInterface>>([]);

  const getValidationPlansAPI = async () => {
    const releaseDetails = await getValidationPlans(projectId);
    console.log(releaseDetails.data);
    setValidationPlans(releaseDetails.data.result);
  };

  const getValidationPlansByReleaseTopicAPI = async () => {
    if (props.releaseTopicId) {
      const result = await getValidationPlansByReleaseTopic(projectId, props.releaseTopicId);
      console.log(result);
      setValidationPlansList(result.data.result);
    }
  };
  useEffect(() => {
    getValidationPlansAPI();
    getValidationPlansByReleaseTopicAPI();
  }, []);

  const addValidationPlanAPI = async (releaseTopicId: string) => {
    const payload = { releaseTopicId: props.releaseTopicId };
    const commitResult = await addValidationPlan(projectId, releaseTopicId, payload);
    if (commitResult.data) {
      enqueueSnackbar('Release Created Successfully', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.SUCCESS,
      });
      getValidationPlansByReleaseTopicAPI();
    } else {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const View = (value: ValidationPlanLinkInterface) => {
    return (
      <Paper sx={{ minWidth: '450px', padding: 1, margin: 2, backgroundColor: '#2c4389' }}>
        <Grid container sx={{ minWidth: '450px' }}>
          <Grid xs={6} sx={{ padding: 1 }}>
            <Typography variant="h5" component="div">
              <Typography variant="h5" component="div" sx={{ color: '#fff' }}>
                {value.result[0]?.name}
              </Typography>
              <Typography variant="overline" component="div" sx={{ color: '#fff' }}>
                {ParseToDate(value.result[0]?.startDate)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Card sx={{ padding: 2 }}>
        <Box>
          <Box sx={{ minWidth: 150 }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" action={action} />
            <br />
            <Autocomplete
              id="filter-demo"
              options={validationPlans}
              getOptionLabel={(option) => option?.name}
              filterOptions={filterOptions}
              sx={{ width: 300 }}
              getOptionKey={(options) => options._id}
              renderInput={(params) => <TextField {...params} label="Validation Plans" />}
              onChange={(event, value, reason, details) => {
                console.log(event);
                console.log(value);
                if (value && value._id) {
                  setInputValue(value._id);
                }
              }}
            />
            <br />
            <Button
              variant="contained"
              onClick={(e) => {
                addValidationPlanAPI(inputValue);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Card>

      {validationPlansList.map((value, index) => View(value))}
    </>
  );
}
