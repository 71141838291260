import { SxProps } from "@mui/material/styles";
import { COLOR_THEME_SECONDARY_FONT_MENU } from "../values/Colors";
import { DEFAULT_FONT_FAMILY } from "../values/FontFamily";

export const SideBarStyle:SxProps = {
  color: COLOR_THEME_SECONDARY_FONT_MENU,
  fontSize: 30,
  fontFamily:DEFAULT_FONT_FAMILY
};
export const SideBarHeadingStyle:SxProps = {
  color: COLOR_THEME_SECONDARY_FONT_MENU,
  fontSize: 15
};
export const SideBarIconColor = { color: '#0c50b0' };
  