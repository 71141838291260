import React, { useEffect, useState } from 'react';
import {
  Button,
  colors,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getReleaseTopicsPagination } from '../../../services/ReleaseTopics';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/components/Tables';
import { CREATE_RELEASE_TOPICS, parseURI } from '../../../utils/parser/URIFormatter';
import { ReleaseTopicsList } from '../../../utils/interface/ReleaseTopicInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { EmptyResult, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { CalculateFromToday, ParseToDate } from '../../../utils/parser/DateParser';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';
import { GridMoreVertIcon } from '@mui/x-data-grid';
const ITEM_HEIGHT = 48;

export function ListReleaseTopics(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [releaseTopic, setReleaseTopics] = useState<ReleaseTopicsList>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const releaseTopicsAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    try {
      //const releaseTopics: any = await getReleaseTopicsPagination(projectId, pageNumber, {});
      getReleaseTopicsPagination(projectId, pageNumber, {})
        .then((releaseTopics) => {
          console.log('Result ', releaseTopics);
          setReleaseTopics(releaseTopics.data);
          setOpen(false);
        })
        .catch((err) => {
          console.log('Result ', err);
          setOpen(false);
        });
    } catch (err) {
      console.log('Failure Catched', err);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseTopicsAPI(value);
  };
  useEffect(() => {
    releaseTopicsAPI();
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Release Topics"
          buttonHref={parseURI(CREATE_RELEASE_TOPICS, projectId)}
          buttonLabel="Create Release Topic"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>
                    Topic Name
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <GridMoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                          },
                        },
                      }}
                    >
                      <MenuItem key={1} onClick={handleClose}>
                        Vivek
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell sx={TableHeaderLabel} title="YYYY/MM/DD">
                    Due
                  </TableCell>
                  <TableCell sx={TableHeaderLabel}>Days Left</TableCell>
                  <TableCell sx={TableHeaderLabel}>Status</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {releaseTopic && releaseTopic.result ? (
                  releaseTopic.result.map((row: any, index: number) => (
                    <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        <Tooltip title={row?.description}>{row?.name}</Tooltip>
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {ParseToDate(row?.plannedReleaseDate)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <DaysLeft date={row?.plannedReleaseDate} status={row.status}></DaysLeft>
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.status}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button sx={{ marginRight: 1 }} variant="outlined" href={`release-topics/${row?._id}/details`}>
                          Board
                        </Button>
                        <Button variant="outlined" href={`release-topics/${row?._id}/edit`}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(releaseTopic?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
