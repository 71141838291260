import React, { useRef } from 'react';
import { Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { GridCloseIcon } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';

import AppRoutes from './AppRoutes';
import theme from './theme';

import './App.css';

function App(props:any): React.ReactElement {
  const notistackRef: any = useRef();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <Button onClick={() => notistackRef?.current.closeSnackbar(key)} style={{ color: '#fff', fontSize: '20px' }}>
            <GridCloseIcon />
          </Button>
        )}
      >
        <AppRoutes {...props}/>;
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
