import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getReleases(projectId: string, productId?: String, pageNumber: number = 1, filterObj: Object = {}) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases?skip=${PageItemCount(
      pageNumber
    )}&size=${PaginationConst.PAGE_SIZE}`,
    params: filterObj,
  });

  return response.data || {};
}

/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createRelease(projectId = '', productId: String, releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases`,
    data: releaseObj,
  });

  return response;
}

/**
 * @description Update Release
 * @param {String} productId
 * @param {String} releaseId
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function updateRelease(projectId:string,productId: String, releaseId: String, releaseObj: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases/${releaseId}`,
    data: releaseObj,
  });

  return response;
}

export async function commitReleaseById(projectId: string, releaseTopicId: string, payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics/${releaseTopicId}/links`,
    data: payload,
  });

  return response;
}

export async function getReleaseById(projectId: string, productId: string, releaseId: string) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases/${releaseId}`,
  });

  return response;
}

export async function getReleaseInsights(projectId: string, releaseId: string) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases/${releaseId}/insights`,
  });

  return response;
}

export async function getReleaseActions(projectId: string, pageNumber: number = 1) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/release-actions?skip=${PageItemCount(pageNumber)}&size=${
      PaginationConst.PAGE_SIZE
    }`,
  });
  return response;
}

export async function publishRelease(projectId: string, productId: string, releaseId: string, payload: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases/${releaseId}/publish`,
    data: payload,
  });
  return response;
}

export async function callback(projectId: string, productId: string, releaseId: string, payload: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}/releases/${releaseId}/call`,
    data: payload,
  });
  return response;
}

export async function voteToRelease(projectId: string, actionId: string, payload: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/release-actions/${actionId}`,
    data: payload,
  });
  return response;
}

export async function getReleaseActionByActionId(projectId: string, actionId: string) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/release-actions/${actionId}`,
  });
  return response;
}
