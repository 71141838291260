import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import {  RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getRoadMaps(projectId:string,pageNumber: number = 1) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/roadmaps?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`
  });

  return response.data || {};
}

/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createRoadMap(projectId:string,releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/roadmaps`,
    data: releaseObj,
  });

  return response;
}
