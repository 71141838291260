import { Typography, Grid, Paper, Link, Card, CardContent, CardActions, Button, Chip } from '@mui/material';
import { Releases } from '../../../utils/interface/ReleaseInterface';

import { parseURI, RELEASE_DETAILS } from '../../../utils/parser/URIFormatter';
import { useParams } from 'react-router-dom';
import { ReleaseCommitmentGrid, ReleaseCommitmentLabel, ReleaseCommitmentSecondaryLabel } from './styles/DetailsStyle';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DueDateTheme } from '../../shared-components/days-left/DaysLeft';
type Props = {
  release: Array<Releases>;
  releaseTopicPlannedReleaseDate: Date | undefined;
  statusCheck?: boolean;
};
export function ReleasesCard(props: Props): React.ReactElement {
  const { projectId = '' } = useParams();
  return (
    <Link href={parseURI(RELEASE_DETAILS, projectId, props.release[0].productId, props.release[0]._id)} underline="none">
      <Card
        sx={Object.assign(
          { minWidth: '500px', background: '#2c4389', mt: 1 },
          DueDateTheme(props.releaseTopicPlannedReleaseDate, props.release[0].plannedReleaseDate, props.statusCheck)
        )}
      >
        <CardContent>
          <Typography variant="h5" sx={{ color: '#fff', borderBottom: '2px solid #fff', padding: 1 }} component="div">
            {props.release[0].name}
            <Chip label={props.release[0].status} sx={{ml:2,fontSize:16 }} color="primary" />
          </Typography>
          <ReleaseCommitmentGrid>
            <ReleaseCommitmentLabel>v{props.release[0].version}</ReleaseCommitmentLabel>
            <ReleaseCommitmentSecondaryLabel>
              {ParseToDate(props.release[0].plannedReleaseDate)}
            </ReleaseCommitmentSecondaryLabel>
          </ReleaseCommitmentGrid>
        </CardContent>
      </Card>
    </Link>
  );
}
