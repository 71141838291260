import { Box, Button, Divider, Fab, Grid } from '@mui/material';
import { IHeaderLabel } from '../../../styles/components';

import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { SideBarIconColor } from '../../../styles/components/Layout';
type Props = {
  buttonLabel?: string;
  buttonHref?: string;
  label: string;
};
export default function MainContentHeader(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <IHeaderLabel>
            <AccountTreeIcon sx={SideBarIconColor} /> {props.label}
            {props.buttonHref ? (
              <Button variant="outlined" sx={{ marginLeft: 2 }} href={props.buttonHref}>
                {props.buttonLabel}
              </Button>
            ) : (
              <></>
            )}
          </IHeaderLabel>
          <Divider />
        </Grid>
        {/* <Grid item>
          <Box textAlign={'right'} mr={4} mb={2}>
            {props.child}
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
}
