import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getReleaseTopicById, patchReleaseTopic } from '../../../services/ReleaseTopics';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import dayjs from 'dayjs';
import React from 'react';
import DrawIcon from '@mui/icons-material/Draw';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { PriorityEnum } from '../../../enum/PriorityEnum';
import { GROOMING, parseURI } from '../../../utils/parser/URIFormatter';
export default function UpdateReleaseTopic() {
  const [open, setOpen] = React.useState(false);
  const { topicId = '', projectId = '' } = useParams();
  const validationSchema = yup.object({
    name: yup.string().required('Please enter name'),
    description: yup.string().required('Please enter description'),
    plannedReleaseDate: yup.string().required('Please enter planned release date'),
  });

  /**
   * @description Create or edit release submission
   */
  const onReleaseFormSubmit = async () => {
    console.log('Update Release TOpic Called');
    
    setOpen(true);
    try {
      // Create new release
      const result = await patchReleaseTopic(projectId, topicId, formik.values);
      // check release has been created or not
      if (result?.data) {
        enqueueSnackbar('Release Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
        //navigate(`/products/${topicId}/release`);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {      
      setOpen(false);
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      plannedReleaseDate: '',
      priority: '',
      reference: '',
      releaseDemo: '',
      topicSource: '',
      topicOwners: '',
    },
    onSubmit: onReleaseFormSubmit,
  });
  const getRelaseByReleaseId = async () => {
    try {
      const { data, status } = await getReleaseTopicById(projectId, topicId);
      console.log(data);
      if (!data) {
        throw new Error('Release not found');
      }
      // Append form values
      formik.setValues({
        name: data.name,
        description: data.description,
        plannedReleaseDate: dayjs(data.plannedReleaseDate) as any,
        priority: data.priority,
        reference: data.reference,
        releaseDemo: data.releaseDemo,
        topicSource: data.topicSource,
        topicOwners: data.topicOwners,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while fething the release information', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.ERROR,
      });
    }
  };

  useEffect(() => {
    // get release informaton in case of edit
    if (topicId) getRelaseByReleaseId();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Update Release-topic" />
        <Grid item xs={8} md={8} lg={8}>
          {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  margin="normal"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  name="description"
                  margin="normal"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Planned Release Date"
                    name="plannedReleaseDate"
                    value={formik.values.plannedReleaseDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('plannedReleaseDate', Date.parse(value));
                    }}
                    slotProps={{
                      textField: {
                        id: 'plannedReleaseDate',
                        variant: 'outlined',
                        fullWidth: true,
                        sx: { marginTop: 2 },
                        error: formik.touched.plannedReleaseDate && Boolean(formik.errors.plannedReleaseDate),
                        helperText: formik.touched.plannedReleaseDate && formik.errors.plannedReleaseDate,
                      },
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Priority</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="priority"
                    value={formik.values.priority}
                    label="Priority"
                    sx={{ textAlign: 'left' }}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={PriorityEnum.high}>High</MenuItem>
                    <MenuItem value={PriorityEnum.medium}>Medium</MenuItem>
                    <MenuItem value={PriorityEnum.low}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="reference"
                  name="reference"
                  margin="normal"
                  label="Reference"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="releaseDemo"
                  name="releaseDemo"
                  margin="normal"
                  label="releaseDemo"
                  value={formik.values.releaseDemo}
                  onChange={formik.handleChange}
                  error={formik.touched.releaseDemo && Boolean(formik.errors.releaseDemo)}
                  helperText={formik.touched.releaseDemo && formik.errors.releaseDemo}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="topicSource"
                  name="topicSource"
                  margin="normal"
                  label="Topic Source"
                  value={formik.values.topicSource}
                  onChange={formik.handleChange}
                  error={formik.touched.topicSource && Boolean(formik.errors.topicSource)}
                  helperText={formik.touched.topicSource && formik.errors.topicSource}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="topicOwners"
                  name="topicOwners"
                  margin="normal"
                  label="Topic Owners"
                  value={formik.values.topicOwners}
                  onChange={formik.handleChange}
                  error={formik.touched.topicOwners && Boolean(formik.errors.topicOwners)}
                  helperText={formik.touched.topicOwners && formik.errors.topicOwners}
                />
              </Grid>

              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="body1" component="div">
                    Please use the below to groom the topic
                  </Typography>
                  <Button
                    sx={{ margin: 2, alignItems: 'center' }}
                    variant="contained"
                    href={parseURI(GROOMING,projectId,topicId)}
                  >
                    <DrawIcon sx={{ padding: '1px' }} fontSize="medium" />
                    Click Here Groom Topic
                  </Button>
                </CardContent>
              </Card>
              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Update
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="submit">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
