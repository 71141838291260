import { styled } from '@mui/material';

export const ILogo = styled('img')({
  src: '/assets/header_logo.png',
  height:120,
  paddingLeft:10,
  marginTop:5,
  width:260

});
export const ILogoSmall = styled('img')({
  src: '/assets/icon.png',
  height:120,
  paddingLeft:10,
  marginTop:5,
  width:260

});
