import { TableHead, TableBody, TableRow, Typography, TableCell, Paper } from '@mui/material';
import React from 'react';
import { TableContentLebel } from '../../../styles/components/Tables';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
type Props = {
  tableBody?: any;
  children?: string | JSX.Element | JSX.Element[] | undefined;
};
export function TableComponentHeader(props: Props): React.ReactElement {
  return <TableHead>{props.children}</TableHead>;
}

export function TableComponentBody(props: Props): React.ReactElement {
  return <TableBody>{props.children}</TableBody>;
}

export function EmptyResult(props: Props): React.ReactElement {
  return (
    <>
      <TableRow sx={{ textAlign: 'center', margin: 2 }}>
        <TableCell colSpan={2} sx={TableContentLebel} component="th" scope="row">
          <Typography sx={{ textAlign: 'center' }} variant="body2" component="div">
            No Record Found
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export function EmptyResultPaper(props: Props): React.ReactElement {
  return (
    <>
      <Paper sx={{ textAlign: 'center', margin: 2 }}>
        <Typography
          sx={{ textAlign: 'center', color: '#000', fontSize: '25', fontWeight: 'bold', padding: 2 }}
          variant="body1"
          component="div"
        >
          Sorry, No Record Found
        </Typography>
      </Paper>
    </>
  );
}
