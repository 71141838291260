import { Container, Grid, Paper, Box, Typography, Button } from '@mui/material'
import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
function ListNotifications() {
    return (
        <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box>
                                <Typography variant="h5" color={'primary'}>
                                    <CircleNotificationsIcon sx={{ fontSize: 30 }} /> Notification
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                </Grid>
            </Grid>

        </Container>
    )
}

export default ListNotifications