import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { AUTH_API_PREFIX, AUTH_SERVICE_HOST } from './RestConstants';

/**
 * @description Login
 * @param {Object} UserLoginData
 * @returns {Promise}
 */
export async function Login({ userName, password }: any) {
  const response = await HttpClient.POST({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/login`,
    data: {
      username: userName,
      password: password,
    },
  });

  return response?.data || {};
}

export async function signUp(object: {}) {
  const response = await HttpClient.POST({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/signup`,
    data: object,
  });

  return response?.data || {};
}

/**
 * @description Logout - Clear user logged session and other values
 */
export function Logout(): void {
  localStorage.clear();
}

export async function getUserInfo() {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/account/6589e334601ac160fecdc479/users/65f9df4eceba5d03db1d4081`,
  });

  return response;
}

export async function getAccountInfo() {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/account/6589e334601ac160fecdc479`,
  });

  return response;
}

export async function getUserGroupList(projectId: string, pageNumber: number = 1) {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/user-groups?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
  });

  return response;
}

export async function getUsersByGroup(projectId: string, userGroupId: string, pageNumber: number = 1) {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/user-groups/${userGroupId}/users?skip=${PageItemCount(pageNumber)}&size=${
      PaginationConst.PAGE_SIZE
    }`,
  });

  return response;
}

export async function addUserIntoGroup(userGroupId: string, payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/user-groups/${userGroupId}/user`,
    data: payload,
  });

  return response;
}

export async function getUserAndAccountInfo(payload: Object = {}) {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/profile`,
  });

  return response;
}
export async function getUsers(pageNumber: number = 1) {
  const response = await HttpClient.GET({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `/auth/v1/users?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
  });

  return response;
}

export async function createdUser(payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/user`,
    data: payload,
  });

  return response;
}

export async function createUserGroup(payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/user-groups`,
    data: payload,
  });

  return response;
}
export async function changePassword(payload: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/credentials`,
    data: payload,
  });

  return response;
}

export async function changeUserPassword(userId: string, payload: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: AUTH_SERVICE_HOST,
    END_POINT: `${AUTH_API_PREFIX}/${userId}/credentials`,
    data: payload,
  });

  return response;
}
