import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../utils/constants/AppConstant';
import React from 'react';
import { getUserAndAccountInfo } from '../../services/Auth';
import { User } from '../../interface/User';
import { useForm, SubmitHandler } from 'react-hook-form';
import { BackdropComponent } from '../shared-components/backdrop/Backdrop';
import MainContentHeader from '../shared-components/main-content/MainContentHeader';
import { CONTAINER_SIZE } from '../../styles/components/SizeConstants';
export default function Profile() {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState<User>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<User>();
  const onSubmit: SubmitHandler<User> = (data) => console.log(data);

  const loadUserInfo = async () => {
    console.log(' Started');
    try {
      setOpen(true);
      const result = await getUserAndAccountInfo();
      setUser(result.data.user);
      reset(result.data.user);
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const component = () => {
    return (
      <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
        <BackdropComponent visible={open} />
        <Grid container spacing={2}>
          <MainContentHeader label="User Info" />
          <Grid item xs={8} md={8} lg={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={12} sx={{}}>
                  <span className="">FirstName</span>
                  <TextField required fullWidth id="firstName" margin="normal" {...register('firstName')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">LastName</span>
                  <TextField required fullWidth id="lastName" margin="normal" {...register('lastName')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">Email</span>
                  <TextField required fullWidth id="email" margin="normal" {...register('email')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">LastName</span>
                  <TextField required fullWidth id="lastName" margin="normal" {...register('lastName')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">Address Line 1</span>
                  <TextField required fullWidth id="addressLine_1" margin="normal" {...register('addressLine_1')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">Address Line 2</span>
                  <TextField required fullWidth id="addressLine_2" margin="normal" {...register('addressLine_2')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">City</span>
                  <TextField required fullWidth id="city" margin="normal" {...register('city')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">State</span>
                  <TextField required fullWidth id="state" margin="normal" {...register('state')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">Country</span>
                  <TextField required fullWidth id="country" margin="normal" {...register('country')} />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <span className="">ZipCode</span>
                  <TextField required fullWidth id="zipCode" margin="normal" {...register('zipCode')} />
                </Grid>

                <br />
                <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                  <Grid item>
                    <center>
                      <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                        SAVE
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      {component()}
    </Container>
  );
}
