import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  DashboardPaperLayout,
  MilestonePaperLayout,
  SimpleFontGreenBigStyle,
  SimpleFontGreenSmallStyle,
} from './styles/Style';
import { useParams } from 'react-router-dom';
import { BackdropSkeletonComponent } from '../shared-components/backdrop/Backdrop';
import { getMileStoneInsights } from '../../services/MilestonesService';
import { Milestones } from '../../utils/interface/MilestonesListInterface';
import { EmptyResult, EmptyResultPaper } from '../shared-components/table/TableComponent';
import StarIcon from '@mui/icons-material/Star';
import CampaignIcon from '@mui/icons-material/Campaign';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {
  ContentGrid,
  ContentMilestoneGrid,
  ContentMilestoneSecondaryGrid,
  IconGrid,
  IconMilestoneGrid,
  ItemsMileStoneGrid,
  NextMileStoneGrid,
} from './styles/MilestoneCardStyles';
import { ParseToDate } from '../../utils/parser/DateParser';
import { SideBarIconColor } from '../../styles/components/Layout';
import LogoutIcon from '@mui/icons-material/Logout';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { MILE_STONES_LIST, parseURI } from '../../utils/parser/URIFormatter';
interface MilestoneResult {
  result: Array<Milestones>;
}

export default function MilestoneInsights() {
  const { projectId = '' } = useParams();
  const [validationPlans, setValidationPlans] = React.useState<MilestoneResult>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const validationListAPI = async () => {
    setLoading(true);
    const result = await getMileStoneInsights(projectId);
    setValidationPlans(result.data);
    setLoading(false);
  };
  useEffect(() => {
    validationListAPI();
  }, []);

  const nextMilestone = (value: Milestones) => {
    return (
      <NextMileStoneGrid>
        <Grid container spacing={2}>
          <Grid item>
            <CampaignIcon fontSize="large" />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Tooltip title={value.description}>
                  <ContentMilestoneGrid>{value.title}</ContentMilestoneGrid>
                </Tooltip>
              </Grid>
              <Grid item>
                <ContentMilestoneSecondaryGrid> {ParseToDate(`${value.targetDate}`)}</ContentMilestoneSecondaryGrid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </NextMileStoneGrid>
    );
  };
  const milestoneItems = (value: string) => {
    return (
      <ItemsMileStoneGrid>
        <IconGrid>
          <SkipNextIcon />
        </IconGrid>
        <ContentGrid>{value}</ContentGrid>
      </ItemsMileStoneGrid>
    );
  };
  const grid = (list: Array<Milestones>) => {
    return (
      <List aria-label="mailbox folders" sx={{ bgcolor: 'background.paper' }}>
        {(list && list.length>0)?list.map((row: Milestones, index: number) => (index === 0 ? nextMilestone(row) : milestoneItems(row.title))):<EmptyResultPaper/>}
        
      </List>
    );
  };

  return (
    <BackdropSkeletonComponent loading={loading}>
      <Paper elevation={3} sx={MilestonePaperLayout}>
        {validationPlans && validationPlans.result ? grid(validationPlans.result) : <EmptyResult />}

        <Button variant="outlined" sx={{ width: '100%' }} href={parseURI(MILE_STONES_LIST,projectId)}>
           Go to Details
        </Button>
      </Paper>
    </BackdropSkeletonComponent>
  );
}
