import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getReleaseTopicsPagination } from '../../../services/ReleaseTopics';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/components/Tables';
import { CREATE_RELEASE_TOPICS, MILE_STONES_CREATE, parseURI } from '../../../utils/parser/URIFormatter';
import { ReleaseTopicsList } from '../../../utils/interface/ReleaseTopicInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { EmptyResult, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { getMileStonesByPagination } from '../../../services/MilestonesService';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';

export function ListMilestones(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [releaseTopic, setReleaseTopics] = useState<ReleaseTopicsList>();

  const releaseTopicsAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    try {
      const releaseTopics: any = await getMileStonesByPagination(projectId, pageNumber, {});
      setReleaseTopics(releaseTopics.data);
    } catch (err) {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
    }

    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseTopicsAPI(value);
  };
  useEffect(() => {
    releaseTopicsAPI();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Milestones"
          buttonHref={parseURI(MILE_STONES_CREATE, projectId)}
          buttonLabel="Create Milestones"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>Title</TableCell>
                  <TableCell sx={TableHeaderLabel}>Target Date</TableCell>
                  <TableCell sx={TableHeaderLabel}>Days Left</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {releaseTopic && releaseTopic.result ? (
                  releaseTopic.result.map((row: any, index: number) => (
                    <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.title}
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {ParseToDate(row?.targetDate)}
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                         <DaysLeft date={row?.targetDate} status={row.status}></DaysLeft>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button sx={{ marginRight: 1 }} variant="outlined" href={`release-topics/${row?._id}/details`}>
                          Board
                        </Button>
                        <Button variant="outlined" href={`release-topics/${row?._id}/edit`}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult/>
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(releaseTopic?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
