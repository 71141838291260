import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { Product } from '../../../interface/Products';
import CloseIcon from '@mui/icons-material/Close';
import { Label } from '@mui/icons-material';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';

export function ProductDashboard(props:any): React.ReactElement {
  const productId = useParams();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [releaseView, toggleReleaseView] = useState('list');

  const [formdata, setFormdata] = useState<Product>();
  const [openDrawer, toggleDrawer] = useState(false);

  const navigate = useNavigate();

  const toggleRelease = () => {
    toggleReleaseView(releaseView === 'list' ? 'card' : 'list');
  };

  const [release, setReleases] = useState([]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    console.log(productId);
    setOpen(false);
};
  const action = (
    <React.Fragment>
        
    </React.Fragment>
);
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
        </Button>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </Container>
  );
}
