import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups3Rounded';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { SideBarHeadingStyle, SideBarIconColor, SideBarStyle } from '../styles/components/Layout';
import PersonIcon from '@mui/icons-material/Person';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { Button, ListItemButton, ListSubheader, Paper, Typography } from '@mui/material';
import { ILogo } from '../styles/components/ILogo';
import ProjectSelector from '../components/shared-components/ProjectSelector/ProjectSelector';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PasswordIcon from '@mui/icons-material/Password';
import {
  ACCOUNT,
  DASHBOARD,
  MILE_STONES_LIST,
  MY_ACTIONS,
  parseURI,
  PRODUCTS_HOME,
  PROFILE,
  PROJECTS,
  RELEASE_TOPICS_LIST,
  ROADMAP_HOME,
  USER_GROUPS,
  USERS,
  VALIDATION_PLANS_HOME,
} from '../utils/parser/URIFormatter';
import { useCookies } from 'react-cookie';

import { configureStore } from '@reduxjs/toolkit';
import { counterSlice } from '../store';
const store = configureStore({
  reducer: counterSlice.reducer,
});
const drawerWidth = 300;

export default function StickLayout(props: any): React.ReactElement {
  const { projectId = localStorage.getItem('projectId') || '66791cc1cbb87b72c4fc7986' } = useParams();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [name, setName] = React.useState('');
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['auth_token']);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  store.subscribe(() => console.log(store.getState()));
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const Logout = () => {
    let date = new Date();
    date.setDate(1);
    date.setMonth(1);
    date.setFullYear(1960);
    removeCookie('auth_token', { path: '/', expires: date });
    console.log('Logout');
    navigate('/');
  };

  const tenantArea = () => {
    const list: any[] = [
      {
        title: 'Dashboard',
        link: parseURI(DASHBOARD, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Road Map',
        link: parseURI(ROADMAP_HOME, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Milestones',
        link: parseURI(MILE_STONES_LIST, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Release Topics',
        link: parseURI(RELEASE_TOPICS_LIST, projectId),
        icon: <AccountTreeIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Validation Plans',
        link: parseURI(VALIDATION_PLANS_HOME, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Product Teams',
        link: parseURI(PRODUCTS_HOME, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'User Groups',
        link: parseURI(USER_GROUPS, projectId),
        icon: <GroupsIcon sx={SideBarIconColor} />,
      },
      {
        title: 'My Actions',
        link: parseURI(MY_ACTIONS, projectId),
        icon: <PendingActionsIcon sx={SideBarIconColor} />,
      },
    ];
    return (
      <>
        {list.map((item) => (
          <ListItemButton component={Link} to={item.link} >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={SideBarIconColor} primary={item.title} />
          </ListItemButton>
        ))}
      </>
    );
  };

  const globalSettings = () => {
    const list: any[] = [
      {
        title: 'Profile',
        link: parseURI(PROFILE, projectId),
        icon: <PersonIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Account',
        link: parseURI(ACCOUNT, projectId),
        icon: <AccountBalanceIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Users',
        link: parseURI(USERS, projectId),
        icon: <PeopleAltIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Change Password',
        link: parseURI(MY_ACTIONS, projectId),
        icon: <PasswordIcon sx={SideBarIconColor} />,
      },
    ];
    return (
      <>
        {list.map((item) => (
          <ListItemButton component={Link} to={item.link} id={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={SideBarStyle} primary={item.title} />
          </ListItemButton>
        ))}
      </>
    );
  };
  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Paper sx={{ padding: '5' }}>
        <ILogo src="/assets/header_logo.png" />
      </Paper>
      <Divider />
      <ProjectSelector />
      <Divider />
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Cock-Pit
          </ListSubheader>
        }
      >
        {/* <Typography sx={SideBarHeadingStyle}>Cock-Pit</Typography> */}

        <ListItemButton component={Link} to={parseURI(PROJECTS, projectId)}>
          <ListItemIcon>
            <DashboardIcon sx={SideBarIconColor} />
          </ListItemIcon>
          <ListItemText sx={SideBarIconColor} primary="Projects" />
        </ListItemButton>
        <Divider />
      </List>

      <List
        sx={{ bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader-tenant-area">
            Tenant Area
          </ListSubheader>
        }
      >
        {tenantArea()}
        <Divider />
      </List>

      <List
        sx={{ bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader-tenant-area">
            Global Setting
          </ListSubheader>
        }
      >
        {globalSettings()}
      </List>
      <Divider />
      <List component="nav" sx={{ bgcolor: 'background.paper' }}>
        <ListItem>
          <Button variant="outlined" sx={{ width: '100%' }} onClick={Logout}>
            <LogoutIcon sx={SideBarIconColor} /> Logout
          </Button>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            backgroundColor: '#365dc3',
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
