import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, useParams } from 'react-router-dom';
import { Releases } from '../../../utils/interface/ReleaseInterface';
import { ErrorMessage, Field, Formik } from 'formik';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ReleaseTopics } from '../../../utils/interface/ReleaseTopicInterface';
import { commitReleaseById } from '../../../services/Release';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { getReleaseTopics, getReleaseTopicsPagination } from '../../../services/ReleaseTopics';
import { getReleaseById } from '../../../services/Release';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: ReleaseTopics) => option.name,
});

export function CommitRelease(): React.ReactElement {
  const { projectId = '', productId = '', releaseId = '' } = useParams();
  const [releaseTopics, setReleaseTopics] = useState([]);
  const [release, setRelease] = useState<Releases>();
  const [inputValue, setInputValue] = React.useState('');
  const getReleaseTopicsAPI = async () => {
    const releaseTopics = await getReleaseTopicsPagination(projectId, 0,{});
    console.log(releaseTopics.result);
    setReleaseTopics(releaseTopics.data.result);
  };
  const getReleaseByIdAPI = async () => {
    if (productId && releaseId) {
      const releaseDetails = await getReleaseById(projectId, productId, releaseId);
      console.log(releaseDetails.data);
      setRelease(releaseDetails.data);
    }
  };
  useEffect(() => {
    getReleaseTopicsAPI();
    getReleaseByIdAPI();
  }, []);

  const commitRelease = async (releaseTopicId: string) => {
    const payload = { releaseId: releaseId };
    try{
      const commitResult = await commitReleaseById(projectId, releaseTopicId, payload);
      console.log("Result ",commitResult);
      if (commitResult && commitResult.data) {
        enqueueSnackbar('Release Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
      }
    }catch(ex){
      console.log("Exception ",ex);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
    }
   
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`Version ${release?.version}`}
        </Typography>
        <Typography variant="h5" component="div">
          {release?.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {/* {//release?.plannedReleaseDate} */}
        </Typography>
        <Typography variant="body2">{release?.description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </React.Fragment>
  );

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Box sx={{ minWidth: 150 }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" action={action} />
        <Card variant="outlined">{card}</Card>

        <br />
        <Autocomplete
          id="filter-demo"
          options={releaseTopics}
          getOptionLabel={(option) => option.name}
          filterOptions={filterOptions}
          sx={{ width: 300 }}
          getOptionKey={(options) => options._id}
          renderInput={(params) => <TextField {...params} label="Release Topics" />}
          onChange={(event, value, reason, details) => {
            console.log(event);
            console.log(value);
            if (value && value._id) {
              setInputValue(value._id);
            }
          }}
          // onInputChange={(event, newInputValue) => {
          //     console.log(event);
          //     console.log(newInputValue);
          //     //setInputValue(newInputValue);
          // }}
        />

        <br />
        <Typography variant="body2">
          Please Confirm , Committing your release to any release topic will directly or indirectly impacts the goal
        </Typography>
        <br />
        <center>
          <Button
            variant="contained"
            onClick={(e) => {
              commitRelease(inputValue);
            }}
          >
            Confirm
          </Button>
        </center>
      </Box>
    </Container>
  );
}
