import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getMileStonesByPagination( projectId:string,pageNumber:number=0,filterObj: Object = {}) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/milestones?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
    params: filterObj,
  });
  console.log(response);
  return response;
}
export async function getMileStoneInsights( projectId:string,pageNumber:number=0,filterObj: Object = {}) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/milestones/insights?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
    params: filterObj,
  });
  console.log(response);
  return response;
}

/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createMilestone(projectId:string,releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/milestones`,
    data: releaseObj,
  });

  return response;
}
