import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getProducts } from '../../../services/Product';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { useParams } from 'react-router-dom';
import { CREATE_PRODUCT, parseURI } from '../../../utils/parser/URIFormatter';
import { ProductTeamsList } from '../../../utils/interface/ProductTeamsnterface';
import { EmptyResult, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';

export function ListProducts(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [productTeams, setProductTeams] = useState<ProductTeamsList>();
  const getProductsAPI = async (pageNumber = 1) => {
    setOpen(true);
    const products = await getProducts(projectId, pageNumber);
    setProductTeams(products);
    setOpen(false);
  };
  useEffect(() => {
    getProductsAPI();
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getProductsAPI(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Product Teams" buttonHref={parseURI(CREATE_PRODUCT, projectId)} buttonLabel="Create" />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell>Topic Name</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Releases</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {productTeams && productTeams.result ? (
                  productTeams?.result.map((row: any, index: number) => (
                    <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button variant="outlined" href={`products/${row?._id}/edit`}>
                          View / Edit
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button variant="outlined" href={`products/${row?._id}/releases`}>
                          Releases
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(productTeams?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
